import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { MediaBackground } from '~/components/molecules';
import { CztImage } from '~/utils/atoms/image';
import { ThemeColors } from '~/utils/theme';
import { Sizes } from '~/components/molecules/imageWrapper/ImageWrapper';
import Tabs, { TabItemInterface } from '~/components/molecules/tabs/Tabs';
import { CztWidgets } from '~/utils/views/widgets';
import { TabsWidgetInterface } from './types';

const rootClass = 'czt-tabs-widget';

const sizes: Sizes[] = [
  {
    size: 100,
    unit: 'vw',
  },
];

const pageSize = 6;

@Component
export default class TabsWidget extends VueComponent<TabsWidgetInterface>
  implements TabsWidgetInterface {
  @Prop()
  public anchorId?: string;

  @Prop()
  public image?: CztImage;

  @Prop({ type: Boolean, default: false })
  public isFirst!: boolean;

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  @Prop({ required: true, type: Array })
  public items!: TabItemInterface[];

  @Prop({ default: false, type: Boolean })
  public side!: boolean;

  @Prop()
  public overlayColor?: ThemeColors;

  public className = CztWidgets.TABS;

  protected itemsLimit: number = pageSize;

  public render() {
    const classes = [rootClass, 'czt-spacer'];

    if (this.isTopSpacingCollapsed) {
      classes.push('czt-spacer--collapse-top');
    }
    if (this.isBottomSpacingCollapsed) {
      classes.push('czt-spacer--collapse-bottom');
    }
    if (this.image) {
      return (
        <div class={classes.join(' ')}>
          <MediaBackground
            image={this.image}
            isFirst={this.isFirst}
            sizes={sizes}
            overlayColor={
              this.image && this.image.src
                ? this.overlayColor
                  ? this.overlayColor
                  : undefined
                : undefined
            }
          >
            {this.renderContent()}
          </MediaBackground>
        </div>
      );
    } else {
      return (
        <v-sheet class={classes.join(' ')}>{this.renderContent()}</v-sheet>
      );
    }
  }

  protected renderContent() {
    return (
      <v-container id={this.anchorId} class={{ 'px-0': this.side }}>
        <v-row>
          <v-col class='py-0'>
            <Tabs
              items={this.items}
              light={this.image?.src ? true : false}
              side={this.side}
            />
          </v-col>
        </v-row>
      </v-container>
    );
  }
}
